import type { ReactNode } from 'react';
import { TFunction } from 'i18next';
import { SvgIcon } from '@mui/material';
import AccountIcon from 'src/icons/account';
import DashboardIcon from 'src/icons/dashboard';
import MarketingIcon from 'src/icons/marketing';
import IntegrationsIcon from 'src/icons/integrations';
import DosagesIcon from 'src/icons/dosages';
import PlanAndPaymentIcon from 'src/icons/plan-and-payment';
import TeamIcon from 'src/icons/team';
import TestParametersIcon from 'src/icons/test-parameters';
import ToolsIcon from 'src/icons/tools';
import ProductIcon from 'src/icons/product';
import LabIcon from 'src/icons/lab';
import ContactIcon from 'src/icons/contact';
import QuoteIcon from 'src/icons/quote';
import InvoiceIcon from 'src/icons/invoice';
import JobIcon from 'src/icons/job';
import PoolIcon from 'src/icons/pool';
import TagIcon from '@mui/icons-material/LocalOffer';
import EquipmentIcon from '@mui/icons-material/Construction';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import CustomFieldsIcon from '@mui/icons-material/TuneOutlined';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import { paths } from 'src/paths';
import { tokens } from 'src/locales/tokens';
import {
  OPERATIONS_CAMPAIGNS,
  OPERATIONS_CONTACTS,
  OPERATIONS_DASHBOARD_ANALYTICS,
  OPERATIONS_DASHBOARD_FINANCE,
  OPERATIONS_INVOICES,
  OPERATIONS_JOBS,
  OPERATIONS_LAB,
  OPERATIONS_POOLS,
  OPERATIONS_PRODUCTS,
  OPERATIONS_QUOTES,
  OPERATIONS_SMS,
  SETUP_BATCH,
  SETUP_DATA_CLEANING,
  SETUP_DOSAGE_GROUPS,
  SETUP_INTEGRATIONS,
  SETUP_JOBS,
  SETUP_LAB,
  SETUP_MY_BUSINESS,
  SETUP_PLAN_AND_PAYMENT,
  SETUP_POOL_CHARACTERISTICS,
  SETUP_QUOTES,
  SETUP_TAGS,
  SETUP_TEAM,
  SETUP_WATER_TESTING,
} from 'src/constants/permission';
import { FIELD_PLAN, LAB_PLAN, PRO_PLAN } from 'src/constants/subscription';

export interface Item {
  disabled?: boolean;
  icon?: ReactNode;
  items?: Item[];
  childRoutes?: string[];
  label?: ReactNode;
  path?: string;
  title: string;
  rootPath?: string;
  permission?: string;
  subscriptions?: string[];
}

export interface Section {
  items: Item[];
  subheader?: string;
  sectionPath?: string;
}

export const getSections = (t: TFunction): Section[] => [
  {
    items: [
      {
        title: t(tokens.nav.overview),
        path: paths.overview,
        icon: (
          <SvgIcon fontSize="small">
            <HomeSmileIcon />
          </SvgIcon>
        ),
      },
    ],
  },
  {
    subheader: t(tokens.nav.operations),
    sectionPath: paths.operations,
    items: [
      {
        title: t(tokens.nav.dashboard),
        path: paths.dashboard.index,
        icon: (
          <SvgIcon>
            <DashboardIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.analytics),
            path: paths.dashboard.analytics,
            permission: OPERATIONS_DASHBOARD_ANALYTICS,
          },
          {
            title: t(tokens.nav.finance),
            path: paths.dashboard.finance,
            permission: OPERATIONS_DASHBOARD_FINANCE,
            subscriptions: [PRO_PLAN, FIELD_PLAN],
          },
        ],
      },
      {
        title: t(tokens.nav.lab),
        path: paths.lab.index,
        permission: OPERATIONS_LAB,
        subscriptions: [PRO_PLAN, LAB_PLAN],
        icon: (
          <SvgIcon>
            <LabIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.waterTest),
            path: paths.lab.waterTest.index,
          },
          {
            title: t(tokens.nav.testHistory),
            path: paths.lab.testHistory,
          },
        ],
      },
      {
        title: t(tokens.nav.jobs),
        path: paths.job.index,
        permission: OPERATIONS_JOBS,
        subscriptions: [PRO_PLAN, FIELD_PLAN],
        icon: (
          <SvgIcon>
            <JobIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.calendar),
            path: paths.job.calendar.index,
          },
          {
            title: t(tokens.nav.jobToInvoice),
            path: paths.job.toInvoiceList,
          },
          {
            title: t(tokens.nav.recurrenceDashboard),
            path: paths.job.recurrence.index,
          },
        ],
      },
      {
        title: t(tokens.nav.quotes),
        path: paths.quote.index,
        permission: OPERATIONS_QUOTES,
        subscriptions: [PRO_PLAN, FIELD_PLAN],
        icon: (
          <SvgIcon>
            <QuoteIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.openQuotes),
            path: paths.quote.openList,
          },
          {
            title: t(tokens.nav.archivedQuotes),
            path: paths.quote.archivedList,
          },
        ],
      },
      {
        title: t(tokens.nav.invoicing),
        path: paths.invoice.index,
        permission: OPERATIONS_INVOICES,
        subscriptions: [PRO_PLAN, FIELD_PLAN],
        icon: (
          <SvgIcon>
            <InvoiceIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.invoices),
            path: paths.invoice.list,
          },
          {
            title: t(tokens.nav.batchQueue),
            path: paths.invoice.batch.queue,
          },
          {
            title: t(tokens.nav.batchInvoices),
            path: paths.invoice.batch.list,
          },
        ],
      },
      {
        title: t(tokens.nav.contacts),
        path: paths.contact.index,
        permission: OPERATIONS_CONTACTS,
        icon: (
          <SvgIcon>
            <ContactIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.pools),
        path: paths.pool.index,
        permission: OPERATIONS_POOLS,
        icon: (
          <SvgIcon>
            <PoolIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.products),
        path: paths.product.index,
        permission: OPERATIONS_PRODUCTS,
        subscriptions: [PRO_PLAN, FIELD_PLAN],
        icon: (
          <SvgIcon>
            <ProductIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.campaigns),
        path: paths.campaign.index,
        permission: OPERATIONS_CAMPAIGNS,
        icon: (
          <SvgIcon>
            <MarketingIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.sms),
        path: paths.sms.index,
        permission: OPERATIONS_SMS,
        icon: (
          <SvgIcon>
            <SmsOutlinedIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.configure),
            path: paths.sms.configure,
          },
          {
            title: t(tokens.nav.sentMessages),
            path: paths.sms.sentMessages,
          },
        ],
      },
    ],
  },
  {
    subheader: t(tokens.nav.setup),
    sectionPath: paths.setup.index,
    items: [
      {
        title: t(tokens.nav.myBusiness),
        path: paths.setup.myBusiness.general,
        permission: SETUP_MY_BUSINESS,
        childRoutes: [paths.setup.myBusiness.brand],
        icon: (
          <SvgIcon>
            <AccountIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.planAndPayment),
        path: paths.setup.planAndPayment.overview,
        permission: SETUP_PLAN_AND_PAYMENT,
        icon: (
          <SvgIcon>
            <PlanAndPaymentIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.team),
        path: paths.setup.team.userList,
        permission: SETUP_TEAM,
        childRoutes: [paths.setup.team.roleList],
        icon: (
          <SvgIcon>
            <TeamIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.quotes),
        path: paths.setup.quotes.index,
        permission: SETUP_QUOTES,
        subscriptions: [PRO_PLAN, FIELD_PLAN],
        icon: (
          <SvgIcon>
            <QuoteIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.email),
            path: paths.setup.quotes.email,
          },
          {
            title: t(tokens.nav.settings),
            path: paths.setup.quotes.settings,
          },
        ],
      },
      {
        title: t(tokens.nav.jobs),
        path: paths.setup.jobs.index,
        permission: SETUP_JOBS,
        subscriptions: [PRO_PLAN, FIELD_PLAN],
        icon: (
          <SvgIcon>
            <JobIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.email),
            path: paths.setup.jobs.email,
          },
          {
            title: t(tokens.nav.sms),
            path: paths.setup.jobs.smsSettings,
          },
          {
            title: t(tokens.nav.jobTemplates),
            path: paths.setup.jobs.jobTemplate.index,
          },
          {
            title: t(tokens.nav.jobSheet),
            path: paths.setup.jobs.jobSheetSettings,
          },
          {
            title: t(tokens.nav.invoice),
            path: paths.setup.jobs.invoiceSettings,
          },
          {
            title: t(tokens.nav.colors),
            path: paths.setup.jobs.colors,
          },
          {
            title: t(tokens.nav.tradingTerms),
            path: paths.setup.jobs.tradingTerm,
          },
        ],
      },
      {
        title: t(tokens.nav.batchBilling),
        path: paths.setup.batchBilling.index,
        permission: SETUP_BATCH,
        subscriptions: [PRO_PLAN, FIELD_PLAN],
        icon: (
          <SvgIcon>
            <InvoiceIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.email),
            path: paths.setup.batchBilling.email,
          },
          {
            title: t(tokens.nav.settings),
            path: paths.setup.batchBilling.settings,
          },
        ],
      },
      {
        title: t(tokens.nav.lab),
        path: paths.setup.lab.index,
        permission: SETUP_LAB,
        subscriptions: [PRO_PLAN, LAB_PLAN],
        icon: (
          <SvgIcon>
            <LabIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.email),
            path: paths.setup.lab.email,
          },
          {
            title: t(tokens.nav.sms),
            path: paths.setup.lab.smsSettings,
          },
          {
            title: t(tokens.nav.settings),
            path: paths.setup.lab.settings,
          },
        ],
      },
      {
        title: t(tokens.nav.integrations),
        path: paths.setup.integration.index,
        permission: SETUP_INTEGRATIONS,
        subscriptions: [PRO_PLAN, FIELD_PLAN],
        icon: (
          <SvgIcon>
            <IntegrationsIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.xero),
            path: paths.setup.integration.xero,
          },
          {
            title: t(tokens.nav.stripe),
            path: paths.setup.integration.stripe,
          },
          {
            title: t(tokens.nav.vend),
            path: paths.setup.integration.vend,
          },
        ],
      },
      {
        title: t(tokens.nav.dataCleaning),
        path: paths.setup.dataCleaning.index,
        permission: SETUP_DATA_CLEANING,
        icon: (
          <SvgIcon>
            <ToolsIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.contacts),
            path: paths.setup.dataCleaning.contact.deduplicate,
          },
          {
            title: t(tokens.nav.pools),
            path: paths.setup.dataCleaning.pool.deduplicate,
          },
        ],
      },
      {
        title: t(tokens.nav.waterTesting),
        path: paths.setup.waterTesting.index,
        permission: SETUP_WATER_TESTING,
        icon: (
          <SvgIcon>
            <TestParametersIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.chemicalTests),
            path: paths.setup.waterTesting.chemicalTest.index,
          },
          {
            title: t(tokens.nav.observationTests),
            path: paths.setup.waterTesting.observationTest.index,
          },
          {
            title: t(tokens.nav.recommendationsOrder),
            path: paths.setup.waterTesting.recommendationsOrder,
          },
        ],
      },
      {
        title: t(tokens.nav.dosageGroups),
        path: paths.setup.dosageGroup.index,
        permission: SETUP_DOSAGE_GROUPS,
        icon: (
          <SvgIcon>
            <DosagesIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.chemicalGroups),
            path: paths.setup.dosageGroup.chemicalGroup.index,
          },
          {
            title: t(tokens.nav.observationGroups),
            path: paths.setup.dosageGroup.observationGroup.index,
          },
        ],
      },
      {
        title: t(tokens.nav.equipment),
        path: paths.setup.equipment.index,
        permission: SETUP_POOL_CHARACTERISTICS,
        icon: (
          <SvgIcon>
            <EquipmentIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.template),
            path: paths.setup.equipment.template.index,
          },
          {
            title: t(tokens.nav.type),
            path: paths.setup.equipment.type.index,
          },
        ],
      },
      {
        title: t(tokens.nav.customFields),
        path: paths.setup.customFields.index,
        permission: SETUP_POOL_CHARACTERISTICS,
        icon: (
          <SvgIcon>
            <CustomFieldsIcon />
          </SvgIcon>
        ),
        items: [
          {
            title: t(tokens.nav.brands),
            path: paths.setup.customFields.brands,
          },
          {
            title: t(tokens.nav.customExceptions),
            path: paths.setup.customFields.customExceptions,
          },
          {
            title: t(tokens.nav.contactRelationships),
            path: paths.setup.customFields.contactRelationships,
          },
          {
            title: t(tokens.nav.contactCategories),
            path: paths.setup.customFields.contactCategories,
          },
          {
            title: t(tokens.nav.recurrencePeriods),
            path: paths.setup.customFields.recurrencePeriods,
          },
          {
            title: t(tokens.nav.sanitisers),
            path: paths.setup.customFields.sanitisers,
          },
          {
            title: t(tokens.nav.surfaces),
            path: paths.setup.customFields.surfaces,
          },
        ],
      },
      {
        title: t(tokens.nav.tags),
        path: paths.setup.tags.index,
        permission: SETUP_TAGS,
        icon: (
          <SvgIcon>
            <TagIcon />
          </SvgIcon>
        ),
      },
    ],
  },
];
